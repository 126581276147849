<template>
  <v-card flat data-cy="login-comp">
    <v-card-text>
      <div class="text-center text-h5 mb-4">{{ $t("auth.title") }}</div>
      <div>{{ $t("auth.infoCas3") }}</div>
    </v-card-text>
    <v-card-text v-if="errorText">
      <v-alert type="error">
        {{ errorText }}
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" :href="authUrlLogin" data-cy="btn-goto-login">{{
        $t("auth.gotoCas3Login")
      }}</v-btn>
      <v-spacer />
    </v-card-actions>
  </v-card>
</template>

<script>
import ComponentStatus from "@/modules/base/componentStatus.mixin";

export default {
  name: "LoginCas",
  mixins: [ComponentStatus],
  components: {},
  props: {
    organisationId: {
      type: String,
      default: "",
    },
    lang: {
      type: String,
      default: "",
    },
  },
  data: () => ({}),
  computed: {
    authUrlLogin() {
      return this.$store.getters["config/getAuthUrlLogin"];
    },
    logoUrl() {
      return (id) => {
        return this.$store.getters["organisations/getLogoUrl"](id);
      };
    },
    showError() {
      return this.$store.getters["status/showError"];
    },
    errorText() {
      return this.$store.getters["status/errorText"];
    },
  },
  created() {
    if (this.lang) {
      this.$store.commit("auth/setOrganisationLanguage", this.lang);
    }
    if (this.organisationId) {
      this.$store.dispatch(
        "organisations/readAppearanceById",
        this.organisationId
      );
    }
  },
};
</script>

<template>
  <v-card flat data-cy="login-comp">
    <!-- <v-row v-show="organisationId && logoLoaded">
      <v-col cols="12" class="d-flex justify-center my-4">
        <v-avatar color="transparent" size="6em" rounded="0">
          <v-img
            :src="logoUrl(organisationId)"
            contain
            @error="logoLoaded = false"
          ></v-img>
        </v-avatar>
      </v-col>
    </v-row> -->

    <v-card-text>
      <div class="text-center text-h5 mb-4">{{ $t("auth.title") }}</div>
      <v-form>
        <v-text-field
          v-model="editUsername"
          prepend-icon="mdi-account"
          :label="$t('auth.login')"
          type="text"
          autocomplete="username"
          data-cy="input-login"
        >
        </v-text-field>
        <v-text-field
          v-model="editPassword"
          prepend-icon="mdi-lock"
          :label="$t('common.password')"
          :append-icon="!viewPassword ? '$viewPassword' : '$unviewPassword'"
          :type="!viewPassword ? 'password' : 'text'"
          @click:append="() => (viewPassword = !viewPassword)"
          @keyup.native.13="login"
          autocomplete="current-password"
          data-cy="input-password"
        >
        </v-text-field>
        <!-- <v-checkbox
          v-model="editRememberMe"
          prepend-icon="mdi-account-clock"
          :label="$t('auth.rememberMe')"
        >
        </v-checkbox> -->
      </v-form>
    </v-card-text>

    <!-- <v-card-actions>
      <v-btn text color="primary" href="/password/reset">{{
        $t("auth.forgotPassword")
      }}</v-btn>
      <v-spacer />
    </v-card-actions> -->
    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" @click="login" data-cy="btn-login">{{
        $t("auth.loginSubmit")
      }}</v-btn>
    </v-card-actions>
    <v-card-actions>
      <v-spacer />
      <v-btn
        text
        small
        :to="forgotPasswordRoute"
        data-cy="link-forgot-password"
        >{{ $t("auth.forgotPassword") }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import ComponentStatus from "@/modules/base/componentStatus.mixin";

export default {
  name: "Login",
  mixins: [ComponentStatus],
  components: {},
  props: {
    organisationId: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
    lang: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    viewPassword: false,
    logoLoaded: true,
    // Edit proxy form data
    editUsername: "",
    editPassword: "",
    editRememberMe: false,
  }),
  computed: {
    forgotPasswordRoute() {
      let route = {
        name: "forgot-password",
      };
      if (this.organisationIdToken) {
        route.query = { oi: this.organisationIdToken };
      }

      return route;
    },
    logoUrl() {
      return (id) => {
        return this.$store.getters["organisations/getLogoUrl"](id);
      };
    },
    impersonationsFrom() {
      return this.$store.getters["auth/getImpersonationsFrom"];
    },
    hasImpersonations() {
      return this.impersonationsFrom.length > 0;
    },
    organisationIdToken() {
      return this.$store.getters["auth/getOrganisationIdToken"];
    },
  },
  created() {
    if (this.lang) {
      this.$store.commit("auth/setOrganisationLanguage", this.lang);
    }
    if (this.organisationId) {
      this.$store.dispatch(
        "organisations/readAppearanceById",
        this.organisationId
      );
    }
  },
  watch: {
    email: {
      immediate: true,
      handler: function (newValue) {
        if (newValue && this.editUsername === "") {
          this.editUsername = newValue;
        }
      },
    },
  },
  methods: {
    login() {
      this.setStatusReading();
      let payload = {
        email: this.editUsername,
        password: this.editPassword,
        remember_me: this.editRememberMe,
      };
      this.$store
        .dispatch("auth/login", payload)
        .then((/* result */) => {
          return this.$store.dispatch("auth/validate");
        })
        .then((/* result */) => {
          this.setStatusReadSuccess();
          this.$nextTick(() => {
            if (this.hasImpersonations) {
              if (this.$route.query.redirect) {
                this.$router.push({
                  name: "impersonate",
                  query: { redirect: this.$route.query.redirect },
                });
              } else {
                this.$router.push({ name: "impersonate" });
              }
            } else {
              if (this.$route.query.redirect) {
                this.$router.push(this.$route.query.redirect);
              } else {
                this.$router.push({ name: "home" });
              }
            }
          });
        })
        .catch((/* error */) => {
          this.setStatusReadError();
          this.$store.commit("status/showError", this.$t("auth.error"));
        });
    },
  },
};
</script>

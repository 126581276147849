<template>
  <v-container class="fill-height" fluid data-cy="login-page">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <Login
          v-if="authMethod == 'internal'"
          :email="email"
          :organisationId="queryOrganisationId"
          :lang="lang"
        />
        <LoginCas
          v-if="authMethod == 'CAS3'"
          :organisationId="queryOrganisationId"
          :lang="lang"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Login from "@/modules/auth/components/Login.vue";
import LoginCas from "@/modules/auth/components/LoginCas.vue";
import ForcedOrganisationIdPages from "@/modules/auth/mixins/forcedOrganisationIdPages.mixin.js";

export default {
  name: "LoginPage",
  mixins: [ForcedOrganisationIdPages],
  components: {
    Login,
    LoginCas,
  },
  computed: {
    authMethod() {
      return this.$store.getters["config/getAuthMethod"];
    },
    email() {
      let email = "";
      if (this.$route.query && this.$route.query.email) {
        email = this.$route.query.email.toString();
      }
      return email;
    },
    lang() {
      let lang = "";
      if (this.$route.query && this.$route.query.lang) {
        lang = this.$route.query.lang.toString();
      }
      return lang;
    },
  },
};
</script>
